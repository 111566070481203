export const bscZaps = [
  {
    // PancakeSwap V2
    zapAddress: '0xe1B35A26Bb04Dd3327Ed5963DA901709Fefe7aB5',
    ammRouter: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
    ammFactory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
    ammPairInitHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
  },
  {
    // PancakeSwap V2
    zapAddress: '0xe1B35A26Bb04Dd3327Ed5963DA901709Fefe7aB5',
    ammRouter: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
    ammFactory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
    ammPairInitHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
  },
  {
    // PancakeSwap V2
    zapAddress: '0xe1B35A26Bb04Dd3327Ed5963DA901709Fefe7aB5',
    ammRouter: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
    ammFactory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
    ammPairInitHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
  },
  {
    // PancakeSwap V2
    zapAddress: '0xe1B35A26Bb04Dd3327Ed5963DA901709Fefe7aB5',
    ammRouter: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
    ammFactory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
    ammPairInitHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
  },
  {
    // PancakeSwap V2
    zapAddress: '0xe1B35A26Bb04Dd3327Ed5963DA901709Fefe7aB5',
    ammRouter: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
    ammFactory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
    ammPairInitHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
  },
];
