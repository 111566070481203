export const bombPools = [
  {
    id: 'bombswap-bombswap-bomb',
    name: 'BOMBSWAP-BOMB LP',
    token: 'BOMBSWAP-BOMB LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0x43C4D5D8fd96558Aa3e2134E93Ed8B7035c4C552',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapBOMBSWAP-BOMB',
    earnedTokenAddress: '0xd7a587c444f5B6cd199Ef4bdE60459046992f33f',
    earnContractAddress: '0xd7a587c444f5B6cd199Ef4bdE60459046992f33f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-bombswap-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['BOMBSWAP', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl: 'https://bombswap.xyz/add/ETH/0xaC029BF2871b3f810AAbF836Adc4F89369027971',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0xaC029BF2871b3f810AAbF836Adc4F89369027971',
  },
  {
    id: 'bombswap-phub-bomb',
    name: 'PHUB-BOMB LP',
    token: 'PHUB-BOMB LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0x4d9d0B5C6D92F3c02F7cB3d8E09ed56530C3999C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapPHUB-BOMB',
    earnedTokenAddress: '0xF45dA2Adf5Ab96e7e6531b424c35B15A7dF3BB36',
    earnContractAddress: '0xF45dA2Adf5Ab96e7e6531b424c35B15A7dF3BB36',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-phub-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['PHUB', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl: 'https://bombswap.xyz/add/ETH/0x27Efe14C951631aF75C4c6B618EFA03634aFb5D0',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0x27Efe14C951631aF75C4c6B618EFA03634aFb5D0',
  },
  {
    id: 'bombswap-bitshare-bomb',
    name: 'BITSHARE-BOMB LP',
    token: 'BITSHARE-BOMB LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0x735Fe37f415405beAD64D4fdA65B186f3367EFE7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapBITSHARE-BOMB',
    earnedTokenAddress: '0x742978A6C563dfE19A6c8c055fF7EB70CF73A141',
    earnContractAddress: '0x742978A6C563dfE19A6c8c055fF7EB70CF73A141',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-bitshare-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['BITSHARE', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl: 'https://bombswap.xyz/add/ETH/0x36e3eC21e523BA0A08308154925D9bF2D5A67194',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0x36e3eC21e523BA0A08308154925D9bF2D5A67194',
  },
  {
    id: 'bombswap-czshare-bomb',
    name: 'CZSHARE-BOMB LP',
    token: 'CZSHARE-BOMB LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0x8442b4aA4fAa81c0dC537f1cE17b1FC5d66ea930',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapCZSHARE-BOMB',
    earnedTokenAddress: '0xc5BE4fB06B5240C0c47FfA66aC87c8a74ADAC95E',
    earnContractAddress: '0xc5BE4fB06B5240C0c47FfA66aC87c8a74ADAC95E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-czshare-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['CZSHARE', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl: 'https://bombswap.xyz/add/ETH/0x9c3AE689eE6907F7CF3b09834596aB42f83b8Db9',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0x9c3AE689eE6907F7CF3b09834596aB42f83b8Db9',
  },
  {
    id: 'bombswap-bohm-bomb',
    name: 'BOHM-BOMB LP',
    token: 'BOHM-BOMB LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0x8AB3255A3A7fa60560F0422A808a428af21A70dC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapBOHM-BOMB',
    earnedTokenAddress: '0xdbB665F0aD4220dF0565Ff1cEb010A8B39094DcC',
    earnContractAddress: '0xdbB665F0aD4220dF0565Ff1cEb010A8B39094DcC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-bohm-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['BOHM', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl: 'https://bombswap.xyz/add/ETH/0x2C6a12d0fAFc78Ff1F7cf8a37F949601c6c8c0ae',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0x2C6a12d0fAFc78Ff1F7cf8a37F949601c6c8c0ae',
  },
  {
    id: 'bombswap-usdc-usdt',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0x48327600253dBaDd735AC12E6ACcA8c22362235E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapUSDC-USDT',
    earnedTokenAddress: '0x71158780F073C459372571599fEC5a34f893785A',
    earnContractAddress: '0x71158780F073C459372571599fEC5a34f893785A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['USDC', 'USDT'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl:
      'https://bombswap.xyz/add/0x0FCbc0baCaD8C73be4Fad141682542b44C4737bB/0x75942DD8bD0C6F845a647E0Fa157b5725077960D',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0x75942DD8bD0C6F845a647E0Fa157b5725077960D',
  },
  {
    id: 'bombswap-bombswap-usdt',
    name: 'BOMBSWAP-USDT LP',
    token: 'BOMBSWAP-USDT LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0x03008690538B6ebB2ca2EAcae465b23600f3936B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapBOMBSWAP-USDT',
    earnedTokenAddress: '0x8e824c6f46cFca62455C2EcD7f06f31b83FAAF9B',
    earnContractAddress: '0x8e824c6f46cFca62455C2EcD7f06f31b83FAAF9B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-bombswap-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['BOMBSWAP', 'USDT'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl:
      'https://bombswap.xyz/add/0x0FCbc0baCaD8C73be4Fad141682542b44C4737bB/0xaC029BF2871b3f810AAbF836Adc4F89369027971',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0xaC029BF2871b3f810AAbF836Adc4F89369027971',
  },
  {
    id: 'bombswap-wbtc-bomb',
    name: 'WBTC-BOMB LP',
    token: 'WBTC-BOMB LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0x0c5eCd7B6D9bb4996F3EE98Ae3CBe7Ed06a66F0C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapBTC-BOMB',
    earnedTokenAddress: '0xF3357b7db74AF82213EE7229bD45204d27E097f7',
    earnContractAddress: '0xF3357b7db74AF82213EE7229bD45204d27E097f7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-wbtc-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['WBTC', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl: 'https://bombswap.xyz/add/ETH/0x140F62aCCC69cb24eABdC0E00b7caaC577cA5b24',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0x140F62aCCC69cb24eABdC0E00b7caaC577cA5b24',
  },
  {
    id: 'bombswap-usdt-bomb',
    name: 'USDT-BOMB LP',
    token: 'USDT-BOMB LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0xfDb4c8454d2a2b41Ed8e2E64Ad2D4764f60518DB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapUSDT-BOMB',
    earnedTokenAddress: '0x8C56eB352aC98ad543bE9F0beEFe292a1aa621Ee',
    earnContractAddress: '0x8C56eB352aC98ad543bE9F0beEFe292a1aa621Ee',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-usdt-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['USDT', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl: 'https://bombswap.xyz/add/ETH/0x0FCbc0baCaD8C73be4Fad141682542b44C4737bB',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0x0FCbc0baCaD8C73be4Fad141682542b44C4737bB',
  },
  {
    id: 'bombswap-usdc-bomb',
    name: 'USDC-BOMB LP',
    token: 'USDC-BOMB LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0xf30C0072dFa69aeE9df375Ef46B8E841530762E6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapUSDC-BOMB',
    earnedTokenAddress: '0x5209A1bfAd7ab97af5BE122029124D827E92D706',
    earnContractAddress: '0x5209A1bfAd7ab97af5BE122029124D827E92D706',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-usdc-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['USDC', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl: 'https://bombswap.xyz/add/ETH/0x75942DD8bD0C6F845a647E0Fa157b5725077960D',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0x75942DD8bD0C6F845a647E0Fa157b5725077960D',
  },
  {
    id: 'bombswap-busd-bomb',
    name: 'BUSD-BOMB LP',
    token: 'BUSD-BOMB LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0x0af490E36c69534D83687636bfc52c726C3FdCf1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapBUSD-BOMB',
    earnedTokenAddress: '0x1F7896f1AAadF0dA36173a6232D0b1094455699B',
    earnContractAddress: '0x1F7896f1AAadF0dA36173a6232D0b1094455699B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-busd-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['BUSD', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl: 'https://bombswap.xyz/add/ETH/0x1356Cff3ffDDF918F13A549861b4Fe936Ff895D4',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0x1356Cff3ffDDF918F13A549861b4Fe936Ff895D4',
  },
  {
    id: 'bombswap-bombswap-xbombswap',
    name: 'BOMBSWAP-XBOMBSWAP LP',
    token: 'BOMBSWAP-XBOMBSWAP LP',
    tokenDescription: 'BombSwap',
    tokenAddress: '0x0b72ff8856e47567949b809F2b475933cc2214F9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombswapBOMBSWAP-XBOMBSWAP',
    earnedTokenAddress: '0x970a6ed897D8C62797368d5BE55520c058639105',
    earnContractAddress: '0x970a6ed897D8C62797368d5BE55520c058639105',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bombswap-bombswap-xbombswap',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BombSwap',
    assets: ['BOMBSWAP', 'XBOMBSWAP'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratMultiLP',
    addLiquidityUrl:
      'https://bombswap.xyz/add/0xaC029BF2871b3f810AAbF836Adc4F89369027971/0x4b6e421e1753Cc25c0f3719f16C5d2388172171c',
    buyTokenUrl:
      'https://bombswap.xyz/swap?outputCurrency=0xaC029BF2871b3f810AAbF836Adc4F89369027971',
  },
  // {
  //   id: 'bombswap-usdc-busd',
  //   name: 'USDC-BUSD LP',
  //   token: 'USDC-BUSD LP',
  //   tokenDescription: 'BombSwap',
  //   tokenAddress: '0x96b5cAE9a24ddDED494e40640B9639e9DE73BD4b',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'bombswapUSDC-BUSD',
  //   earnedTokenAddress: '0xEF958860Eeb4C2Eb8749a99AB4a24F75772d8B4F',
  //   earnContractAddress: '0xEF958860Eeb4C2Eb8749a99AB4a24F75772d8B4F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bombswap-usdc-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'active',
  //   platform: 'BombSwap',
  //   assets: ['USDC', 'BUSD'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratMultiLP',
  //   addLiquidityUrl:
  //     'https://bombswap.xyz/add/0x1356Cff3ffDDF918F13A549861b4Fe936Ff895D4/0x0889cE27aDE6231a9820E34bA6E68fc0142b8d18',
  //   buyTokenUrl:
  //     'https://bombswap.xyz/swap?outputCurrency=0x0889cE27aDE6231a9820E34bA6E68fc0142b8d18',
  // },
];
