import { govPoolABI } from '../abi';

export const polygonStakePools = [
  {
    id: 'bifi-polygon',
    name: 'BIFI',
    logo: 'single-assets/BIFI.png',
    token: 'BIFI',
    tokenDecimals: 18,
    tokenAddress: '0xFbdd194376de19a88118e84E279b977f165d01b8',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'MATIC',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    earnContractAddress: '0xDeB0a777ba6f59C78c654B8c92F80238c8002DD2',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'WMATIC',
    partnership: false,
    status: 'active',
    fixedStatus: true,
    partners: [
      {
        logo: 'stake/beefy/beefyfinance.png',
        logoNight: 'stake/beefy/beefyfinance_night.png',
        background: 'stake/beefy/background.png',
        text: "BombFarm is The Multi-Chain Yield Optimizer across many blockchains, enabling users to earn autocompounded yield on their crypto. Did you know also that you can own a piece of Beefy itself? Beefy runs on its governance token, BIFI. The token has a set supply of 80,000 across all chains; no more may be minted, ever! As a holder of BIFI you may create and vote on important DAO proposals, and you become dividend-eligible to earn a share of every compounding harvest on Beefy vaults, hour by hour. Here on Polygon, you just need to stake BIFI in this reward pool, or in the autocompounding BIFI Maxi vault on the main page. For this pool, MATIC dividends are gathered and sent proportionally to each staker. Stake here, return later to claim the MATIC you've earned.",
        website: 'https://www.vaults.peghub.com',
        social: {
          telegram: 'http://t.me/beefyfinance',
          twitter: 'https://twitter.com/beefyfinance',
        },
      },
    ],
  },

  {
    id: 'moo_kyber-usdc-jeur-jarvis',
    name: 'Jarvis',
    assets: ['jEUR', 'USDC'],
    token: 'mooKyberUSDC-jEUR',
    tokenDecimals: 18,
    tokenAddress: '0x4A041eD4dda1cf460D557E1bdCBf0b29c02A1b3c',
    tokenOracle: 'lps',
    tokenOracleId: 'kyber-usdc-jeur',
    earnedToken: 'BIFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xFbdd194376de19a88118e84E279b977f165d01b8',
    earnContractAddress: '0xE2C04ff677394872e43ee06B4a28a3cbd542A327',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BIFI',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1637584703,
    partners: [
      {
        logo: 'stake/jarvis/logo.png',
        background: 'stake/jarvis/bg.png',
        text: 'Jarvis Network is a set of protocols and applications to bring DeFi to everyone. Its first protocol, Synthereum, pioneers a capital efficient manner to issue and exchange synthetic fiat currencies on Ethereum and Polygon. Called jFIAT, these stablecoins are redeemable for USDC at the oracle price, making them stable and liquid.',
        website: 'https://jarvis.network/',
        social: {
          telegram: 'https://t.me/jarvisnetwork',
          twitter: 'https://twitter.com/jarvis_network',
        },
      },
    ],
  },

  {
    id: 'moo_curve-am3crv-jarvis',
    name: 'Jarvis',
    logo: 'polygon/CURVE-3Pool.png',
    token: 'mooCurveAm3CRV',
    tokenDecimals: 18,
    tokenAddress: '0xAA7C2879DaF8034722A0977f13c343aF0883E92e',
    tokenOracle: 'lps',
    tokenOracleId: 'curve-am3crv',
    earnedToken: 'jEUR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x4e3Decbb3645551B8A19f0eA1678079FCB33fB4c',
    earnContractAddress: '0x5936Db923b6E1727DA19d801eC22CeE555b6047b',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'jEUR',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1636527713,
    partners: [
      {
        logo: 'stake/jarvis/logo.png',
        background: 'stake/jarvis/bg.png',
        text: 'Jarvis Network is a set of protocols and applications to bring DeFi to everyone. Its first protocol, Synthereum, pioneers a capital efficient manner to issue and exchange synthetic fiat currencies on Ethereum and Polygon. Called jFIAT, these stablecoins are redeemable for USDC at the oracle price, making them stable and liquid.',
        website: 'https://jarvis.network/',
        social: {
          telegram: 'https://t.me/jarvisnetwork',
          twitter: 'https://twitter.com/jarvis_network',
        },
      },
    ],
  },

  {
    id: 'moo_BIFI-jarvis',
    name: 'Jarvis',
    logo: 'single-assets/BIFI.png',
    token: 'mooPolygonBIFI',
    tokenDecimals: 18,
    tokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'BIFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xFbdd194376de19a88118e84E279b977f165d01b8',
    earnContractAddress: '0x68161e06104d2d2f48cBfE7f6970720BB62DCA92',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BIFI',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1635159408,
    partners: [
      {
        logo: 'stake/jarvis/logo.png',
        background: 'stake/jarvis/bg.png',
        text: 'Jarvis Network is a set of protocols and applications to bring DeFi to everyone. Its first protocol, Synthereum, pioneers a capital efficient manner to issue and exchange synthetic fiat currencies on Ethereum and Polygon. Called jFIAT, these stablecoins are redeemable for USDC at the oracle price, making them stable and liquid.',
        website: 'https://jarvis.network/',
        social: {
          telegram: 'https://t.me/jarvisnetwork',
          twitter: 'https://twitter.com/jarvis_network',
        },
      },
    ],
  },

  {
    id: 'moo_QUICK-polysage',
    name: 'PolySage',
    logo: 'single-assets/QUICK.png',
    token: 'mooQuick',
    tokenDecimals: 18,
    tokenAddress: '0x659418cc3cf755F5367a51aDb586a7F770Da6d29',
    tokenOracle: 'tokens',
    tokenOracleId: 'QUICK',
    earnedToken: 'polySAGE',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x2ed945Dc703D85c80225d95ABDe41cdeE14e1992',
    earnContractAddress: '0xC01b5D452F2a5571E8FbC2D589Ab940DB7B16EFA',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'polySAGE',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1635001080,
    partners: [
      {
        logo: 'stake/polysage/logo.png',
        background: 'stake/polysage/bg.png',
        text:
          'PolySage Finance is a next-generation yield farming protocol on the Polygon network with lots of unique and creative features that enable you to earn a passive income. We are trying to create a protocol like Yearn, but with a reduced token supply and high value for Polygon Network users, LP providers and stakers.\n' +
          '\n' +
          'PolySage is a Layered farm that is temporary.',
        website: 'https://polysage.finance/',
        social: {
          telegram: 'https://t.me/polywisedefi',
          twitter: 'https://twitter.com/polywisefinance',
        },
      },
    ],
  },

  {
    id: 'moo_bifi-usdc-cafe',
    name: 'CafeSwap',
    assets: ['BIFI', 'USDC'],
    token: 'mooSushiUSDC-BIFI',
    tokenDecimals: 18,
    tokenAddress: '0x03F69AAF4c8512f533Da46cC9eFd49C4969e3CB8',
    tokenOracle: 'lps',
    tokenOracleId: 'sushi-usdc-bifi',
    earnedToken: 'pBREW',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xb5106A3277718eCaD2F20aB6b86Ce0Fee7A21F09',
    earnContractAddress: '0x602fAEa31a6F388559E88B93666Ff5908CAE2c9c',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'pBREW',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1635796979,
    partners: [
      {
        logo: 'stake/cafeswap/logo.png',
        background: 'stake/cafeswap/bg.png',
        text: 'CafeSwap is a yield farming and staking platform on BSC Chain and polygon. We have chosen BSC and Polygon because of its low tnx fees and faster speed. We have built this project to provide the best experience with farming while regulating the supply, We believe in partnerships hence we aim to bring all BSC and Polygon DeFi ecosystems in one place to have a friendly ecosystem for all of us.',
        website: 'https://cafeswap.finance',
        social: {
          telegram: 'https://t.me/CafeSwap',
          twitter: 'https://twitter.com/cafeswapfinance',
        },
      },
    ],
  },
  {
    id: 'moo_curve-ren-tetu',
    name: 'Tetu',
    logo: 'single-assets/renBTC.png',
    token: 'mooCurveRen',
    tokenDecimals: 18,
    tokenAddress: '0x8c9d3bc4425773bd2f00c4a2ac105c5ad73c8141',
    tokenOracle: 'lps',
    tokenOracleId: 'curve-poly-ren',
    earnedToken: 'TETU',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x255707b70bf90aa112006e1b07b9aea6de021424',
    earnContractAddress: '0x73EA48822D7802590294a56d153b3458849A41C2',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'TETU',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1633848198,
    partners: [
      {
        logo: 'stake/tetu/logo.png',
        background: 'stake/tetu/bg.png',
        text: `TETU is a DeFi application built on Polygon that implements automated yield farming strategies in order to provide investors with a safe and secure method of receiving a high yield on their investments. TETU tokens represent a share of the collective efforts and expertise of the team who are wholly focused on creating robust income streams for Tetu users. Audited by PeckShield, our tech speaks for itself, but more important are the people behind it forming a culture of inclusivity, transparency, and a global community who all wants high yields secured by competent technology.`,
        website: 'https://app.tetu.io/',
        social: {
          telegram: 'https://t.me/tetu_io',
          twitter: 'https://twitter.com/tetu_io',
        },
      },
    ],
  },

  {
    id: 'moo_banana_matic-polywise',
    name: 'PolyWise',
    assets: ['BANANA', 'MATIC'],
    token: 'mooApeSwapBANANA-MATIC',
    tokenDecimals: 18,
    tokenAddress: '0xADA7F98fb2594E76914EB593e74B348A498Ea5Bd',
    tokenOracle: 'lps',
    tokenOracleId: 'ape-banana-matic',
    earnedToken: 'WISE',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x4c19DdeebAF84cA3A255730295AD9d824D4Ff51f',
    earnContractAddress: '0x4d20db515199B7C799Ca0da4e2Eaedc99F1367Fc',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'polyWISE',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1633493417,
    partners: [
      {
        logo: 'stake/polywise/logo.png',
        background: 'stake/polywise/bg.png',
        text: `PolyWise is a high-yield on Polygon which is opening dividend pools soon. You will be able to stake $WISE and earn $USDC and $WMATIC. PolyWise is audited by Paladin, KYC'd by rugdoc and has a low risk rating on Rugdoc.`,
        website: 'https://polywise.finance/',
        social: {
          telegram: 'https://t.me/polywisedefi',
          twitter: 'https://twitter.com/polywisefinance',
        },
      },
    ],
  },

  {
    id: 'moo_curve-tricrypto-polyalpha',
    name: 'PolyAlpha',
    logo: 'uncategorized/ATRICRYPTO.png',
    token: 'mooCurveATriCrypto3',
    tokenDecimals: 18,
    tokenAddress: '0x5A0801BAd20B6c62d86C566ca90688A6b9ea1d3f',
    tokenOracle: 'lps',
    tokenOracleId: 'curve-poly-atricrypto3',
    earnedToken: 'ALPHA',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0B048D6e01a6b9002C291060bF2179938fd8264c',
    earnContractAddress: '0x0C2741b8eD387803D876Cd63A67dD814a029A4f1',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'polyALPHA',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1633420100,
    partners: [
      {
        logo: 'stake/polyalpha/logo.png',
        background: 'stake/polyalpha/bg.png',
        text: `PolyAlpha Finance is the second layer to PolygonFarm’s ecosystem and it’s another famers’ fav! Placing the security of investors’ funds as the topmost priority, 2 full security audits have been completed with CertiK and Paladin, two of the best blockchain security experts. To further signal transparency, the project owner has again completed the RugDoc KYC and received a low-risk review. In just 10 days, PolyAlpha has reached from $8M to $10M TVL (~$1.2M MC), over $500K in liquidity, and averaged just under $1M in 24-hour trading volume. The relatively higher trading volume for the native token $ALPHA is highly advantageous for liquidity providers and Beefy vaults will no question elevate benefits for both communities! With a scares supply of only 9300 and a constant emission rate of 0.011/block, $ALPHA’s tokenomics are built for success!`,
        website: 'https://polyalpha.finance/',
        social: {
          telegram: 'https://t.me/PolyAlphaFi',
          twitter: 'https://twitter.com/PolyAlphaFi',
        },
      },
    ],
  },
  {
    id: 'moo_curve-poly-sandman',
    name: 'Sandman',
    logo: 'polygon/CURVE-3Pool.png',
    token: 'mooCurveAm3CRV',
    tokenDecimals: 18,
    tokenAddress: '0xAA7C2879DaF8034722A0977f13c343aF0883E92e',
    tokenOracle: 'lps',
    tokenOracleId: 'curve-am3crv',
    earnedToken: 'DELIRIUM',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x238779aFfE6FFD475cB7e84582FcA7789F310Dc8',
    earnContractAddress: '0x7ee6B34B51eAEbc887A16616AfE0855795B6ac74',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'DELIRIUM',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1633058948,
    partners: [
      {
        logo: 'stake/sandman/logo.png',
        background: 'stake/sandman/bg.png',
        text: `The Greatest Multilayered Hybrid Yield Farming, NFTs & Vaults.
The First Stealth Presale ever, sold out in under 30 secs. On its path to Community-Owned AMM, Gambling, and a premier DAO.
We have several uses for your NFTs: NFTs Battles, NFTs farm boosting, NFT's Game Cards over layer.
Sandman Finance was the first project to present the swap/utility token. Where you are rewarded just by holding the presale token. Never seen before!`,
        website: 'https://sandman.finance/',
        social: {
          telegram: 'https://t.me/SandMan_Finance',
          twitter: 'https://twitter.com/sandman_finance',
        },
      },
    ],
  },
  {
    id: 'moo_bifi-yieldwatch',
    name: 'Yieldwatch',
    logo: 'single-assets/BIFI.png',
    token: 'mooPolygonBIFI',
    tokenDecimals: 18,
    tokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'WATCH',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x09211Dc67f9fe98Fb7bBB91Be0ef05f4a12FA2b2',
    earnContractAddress: '0x71a4449dD18177A1a19fEF671558964f10AF4be8',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'WATCHpoly',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1631482291,
    partners: [
      {
        logo: 'stake/yieldwatch/logo.png',
        background: 'stake/yieldwatch/bg.png',
        text: 'yieldwatch.net is a smart yield farming dashboard that lets you monitor your liquidity pools, yield farming and token staking performance with a casual and sleek UI, which is optimised for mobile use. Just like the lifeguards in Malibu watch over the swimmers, we watch over your yield.',
        website: 'https://www.yieldwatch.net/',
        social: {
          telegram: 'https://t.me/yieldwatch',
          twitter: 'https://twitter.com/yieldwatch',
        },
      },
    ],
  },
  {
    id: 'moo_bifi-pearzap',
    name: 'PearZap',
    logo: 'single-assets/BIFI.png',
    token: 'mooPolygonBIFI',
    tokenDecimals: 18,
    tokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'PEAR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xc8bcb58caEf1bE972C0B638B1dD8B0748Fdc8A44',
    earnContractAddress: '0x77D205420cE5557cd8E9DEFC915Fd0D701CCaEa3',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'PEAR',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1629328696,
    partners: [
      {
        logo: 'stake/pearzap/logo.png',
        background: 'stake/pearzap/bg.png',
        text: "PearZap Finance is a yield farming project on the Polygon Chain, born out of the idea of bringing consistency in high APR's and security to our investors through our $Pear native token. Join the PearZap family and be part of a project that prides itself in quality partnerships and delivering on the promises we make, no matter how juicy they may be. 🍐⚡️",
        website: 'https://pearzap.com/',
        social: {
          telegram: 'https://t.me/pearzap',
          twitter: 'https://twitter.com/pearzap',
        },
      },
    ],
  },
  {
    id: 'moo_bifi-polypup',
    name: 'PolyPup',
    logo: 'single-assets/BIFI.png',
    token: 'mooPolygonBIFI',
    tokenDecimals: 18,
    tokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'BALL',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x883abe4168705d2e5da925d28538b7a6aa9d8419',
    earnContractAddress: '0x6B30E11d72AA1D14f79Ba52aF3139F798DFca18d',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BALL',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1629982597,
    partners: [
      {
        logo: 'stake/polypup/logo.png',
        background: 'stake/polypup/background.png',
        text: `PolyPup Finance is a new DeFi project on Polygon featuring a deflationary token model. The main features are a low emission rate, low supply, and a layered farming approach to the platform. This model will help end-users earn maximum yields over a long time. Buyback and burns will help stabilize a price floor for long term holders.`,
        website: 'https://bone.polypup.finance/',
        social: {
          telegram: 'https://t.me/PolyPupFarm',
          twitter: 'https://twitter.com/PolyPup1',
        },
      },
    ],
  },
  {
    id: 'moo_bifi-polyfarm',
    name: 'PolygonFarm',
    logo: 'single-assets/BIFI.png',
    token: 'mooPolygonBIFI',
    tokenDecimals: 18,
    tokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'SPADE',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19',
    earnContractAddress: '0x3439A5815153CA1156828bBa89Aa332f41534602',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'SPADE',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1629094811,
    partners: [
      {
        logo: 'stake/polyfarm/logo.png',
        background: 'stake/polyfarm/bg.png',
        text: "PolygonFarm Finance is a community led next generation sustainable stable-yield farming protocol established exclusively on the Polygon ecosystem. PolygonFarm Finance's Admin completed KYC with RugDoc. PolygonFarm Finance has deflationary token model with very limited maximum supply of 500,000 SPADE tokens & very low emission rate. There will be multiple layered farms by PolygonFarm and each layer will also has pools with previous layers' native tokens.",
        website: 'https://polygonfarm.finance/',
        social: {
          telegram: 'https://t.me/PolygonFarmFinance',
          twitter: 'https://twitter.com/PolygonFarmFi',
        },
      },
    ],
  },
  {
    id: 'moo_bifi-lithium',
    name: 'PolyWantsACracker',
    logo: 'single-assets/BIFI.png',
    token: 'mooPolygonBIFI',
    tokenDecimals: 18,
    tokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'mooPolyCrackerLITH',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xE08d7B24E2D22B242dEA3DE85032261810F35B09',
    earnContractAddress: '0xC1d4DaBE517B5715077082ade69872ab82F86899',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'LITHIUM',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1627849794,
    partners: [
      {
        logo: 'stake/polycracker/logo.png',
        background: 'stake/polycracker/bg.png',
        text: "PolyWantsACracker is a yield farm on Polygon Network (MATIC). It was designed in response to specific demand from the yield farming community, and the farm's LITHIUM token will provide superior value to investors by being used in the farm's next layer, which will introduce genre-redefining features and usher in an entirely new generation of sustainable, user-owned yield farms.",
        website: 'https://polywantsacracker.farm/',
        social: {
          telegram: 'https://t.me/PolyWantsACracker_Farm',
          twitter: 'https://twitter.com/PolyWantsAFarm',
        },
      },
    ],
  },
  {
    id: 'moo_polyyeld-xyeld',
    name: 'PolyYeld',
    logo: 'stake/xyeld/xyeld-matic.svg',
    token: 'mooPolyyeldL2QuickWMATIC-xYELD',
    tokenDecimals: 18,
    tokenAddress: '0xa450f23570868A33bc66bF60f2268E85575bbDC9',
    tokenOracle: 'lps',
    tokenOracleId: 'polyyeld-xyeld-quick-wmatic-xyeld',
    earnedToken: 'mooPolyyeldL2xYELD',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x2Fa3A4671b977a8f0c16334e4CF16EA91F22db75',
    earnContractAddress: '0x6b4971b8dAfc3c426EdE7E629394f31B0BdF3c16',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'xYELD',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1627595163,
    partners: [
      {
        logo: 'stake/xyeld/logo.png',
        background: 'stake/xyeld/bg.png',
        text: 'PolyYeld Finance is a dedicated team of five developers, aiming to deliver a high value yield farming protocol for Polygon users; combining features from other DeFi projects with a twist of their own unique and innovative ideas for their investors to gain maximum yields. Powered by Polygon; the first well-structured, easy-to-use platform for Ethereum scaling and infrastructure development. PolyYeld Finance is a next-generation yield farming protocol on the Polygon network with lots of unique and creative features that enable you to earn a passive income. We are trying to create a protocol like Yearn, but with a reduced token supply and high value for Polygon Network users, LP providers and stakers. There will only be 62,100 YELD tokens which will be minted throughout the course of 2-4 months.',
        website: 'https://layer.polyyeld.finance/',
        social: {
          telegram: 'https://t.me/polyyeld',
          twitter: 'https://twitter.com/PolyYeldFinance',
        },
      },
    ],
  },
  {
    id: 'moo_jetswap-pwings',
    name: 'JetSwap',
    logo: 'polygon/USDC-USDT.png',
    token: 'mooJetSwapUSDC-USDT',
    tokenDecimals: 18,
    tokenAddress: '0x93c9f29CF2496e73f3d8b07055e2359267207147',
    tokenOracle: 'lps',
    tokenOracleId: 'jetswap-poly-usdc-usdt',
    earnedToken: 'mooJetSwapPWINGS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xa55e8326a9AC7B0bFcA5E6Fc9Cf1141E2dc11f67',
    earnContractAddress: '0x9231457582c0E6F3c631e93AEA2d063F59606710',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'pWINGS',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1626566247,
    partners: [
      {
        logo: 'stake/jetswap/logo.png',
        background: 'stake/jetswap/background.png',
        text: 'Jetswap is a decentralized Automated Market Maker (AMM) on Polygon with low fees and instant trade execution. Trade from the comfort of your own wallet! Trade directly from your favorite wallet application! There are no accounts to set up or trading limits. You have full control over your assets and Jetswap has 0 control over your assets. You can earn BIG WINGS rewards when you deposit your Jetswap WINGS-LP to the WINGS farm. The WINGS LP tokens generate trading fees for the depositor! Even if there is not a supported WINGS farm you can still earn a percentage of every trading fee.',
        website: 'https://polygon.jetswap.finance/',
        social: {
          telegram: 'https://t.me/jetfuelfinance',
          twitter: 'https://twitter.com/Jetfuelfinance',
        },
      },
    ],
  },
  {
    id: 'moo_mai_usdc_matic-qidao',
    name: 'QiDao',
    logo: 'polygon/MIMATIC-USDC.png',
    token: 'mooMaiUSDC-miMATIC',
    tokenDecimals: 18,
    tokenAddress: '0xebe0c8d842AA5A57D7BEf8e524dEabA676F91cD1',
    tokenOracle: 'lps',
    tokenOracleId: 'mai-usdc-mimatic',
    earnedToken: 'QI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x580a84c73811e1839f75d86d75d88cca0c241ff4',
    earnContractAddress: '0xd1Cae9751268Eb4f93bfdE9A4788F312D2c0D045',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'QI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1626534866,
    partners: [
      {
        logo: 'stake/qidao/logo.png',
        background: 'stake/qidao/background.png',
        text: `QiDao is Polygon's first native stablecoin protocol and a way for you to hold on to your crypto while still being able to spend its value. It allows you to borrow stablecoins at 0% interest, without selling your crypto. MAI is a stablecoin collateralized by your MATIC holdings.`,
        website: 'https://www.mai.finance/',
        social: {
          telegram: 'https://t.me/QiDaoProtocol',
          twitter: 'https://twitter.com/QiDaoProtocol',
        },
      },
    ],
  },
  {
    id: 'moo_poly_usdc-bone',
    name: 'PolyPup',
    logo: 'single-assets/USDC.svg',
    token: 'mooPolypupUSDC-BONE',
    tokenDecimals: 18,
    tokenAddress: '0x8Ce906F6f383c31b09B1D2A5f2c9f480b87ceb58',
    tokenOracle: 'lps',
    tokenOracleId: 'polypup-usdc-bone',
    earnedToken: 'mooPolypupBONE',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x8Fa291074B9E28055fFdBCd4C1C1Dad67B9a130A',
    earnContractAddress: '0xa8b86b9AF7e844DA90A2e72840Ad01CCBD11EdC3',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BONE',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1626560038,
    partners: [
      {
        logo: 'stake/polypup/logo.png',
        background: 'stake/polypup/background.png',
        text: `PolyPup Finance is a new DeFi project on Polygon featuring a deflationary token model. The main features are a low emission rate, low supply, and a layered farming approach to the platform. This model will help end-users earn maximum yields over a long time. Buyback and burns will help stabilize a price floor for long term holders.`,
        website: 'https://bone.polypup.finance/',
        social: {
          telegram: 'https://t.me/PolyPupFarm',
          twitter: 'https://twitter.com/PolyPup1',
        },
      },
    ],
  },
  {
    id: 'moo_atricrypto-bone',
    name: 'Boneswap',
    logo: 'uncategorized/ATRICRYPTO.png',
    token: 'mooCurveATriCrypto',
    tokenDecimals: 18,
    tokenAddress: '0x3dab1aCB811dc4C8b2FdC77812552f4d4Efd0A8c',
    tokenOracle: 'lps',
    tokenOracleId: 'curve-poly-atricrypto',
    earnedToken: 'BONE',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x80244c2441779361f35803b8c711c6c8fc6054a3',
    earnContractAddress: '0xed5010eDF8812003B7d4c9a69E7AfCBceaB62F4f',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BONEswap',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1626212502,
    partners: [
      {
        logo: 'stake/boneswap/logo.png',
        background: 'stake/boneswap/background.png',
        text: `Bone Swap is a full ecosystem that have yield farming and automated market-making (AMM) for the Polygon network. We introduce Decentralized Exchange (DEX), Yield Farm, Info Service, which is the strong and secure foundation with our own BONE token as the center of our service.`,
        website: 'https://farm.boneswap.finance',
        social: {
          telegram: 'https://t.me/boneswapfi',
          twitter: 'https://twitter.com/boneswapfi',
        },
      },
    ],
  },
  {
    id: 'moo_curve-poly-ren',
    name: 'PolyPup',
    logo: 'single-assets/renBTC.png',
    token: 'mooCurveRen',
    tokenDecimals: 18,
    tokenAddress: '0x8c9d3Bc4425773BD2F00C4a2aC105c5Ad73c8141',
    tokenOracle: 'lps',
    tokenOracleId: 'curve-poly-ren',
    earnedToken: 'mooPolypupBONE',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x8Fa291074B9E28055fFdBCd4C1C1Dad67B9a130A',
    earnContractAddress: '0x54c9B796a96877dee81E1A22e9DfA2488ab2675D',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BONE',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1625858058,
    partners: [
      {
        logo: 'stake/polypup/logo.png',
        background: 'stake/polypup/background.png',
        text: `PolyPup Finance is a new DeFi project on Polygon featuring a deflationary token model. The main features are a low emission rate, low supply, and a layered farming approach to the platform. This model will help end-users earn maximum yields over a long time. Buyback and burns will help stabilize a price floor for long term holders.`,
        website: 'https://bone.polypup.finance/',
        social: {
          telegram: 'https://t.me/PolyPupFarm',
          twitter: 'https://twitter.com/PolyPup1',
        },
      },
    ],
  },
  {
    id: 'moo_matic_yeld-yeld',
    name: 'PolyYeld',
    logo: 'single-assets/YELD.png',
    token: 'mooPolyyeldWMATIC-YELD',
    tokenDecimals: 18,
    tokenAddress: '0xbea5D2Aed651F579fa3e7ADAA2eC51276B2ea420',
    tokenOracle: 'lps',
    tokenOracleId: 'polyyeld-quick-wmatic-yeld',
    earnedToken: 'YELD',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891',
    earnContractAddress: '0x86EB0e64Ca632543BE37e73836d84eD612bb7C34',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'YELD',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1625530839,
    partners: [
      {
        logo: 'stake/polyyeld/logo.png',
        background: 'stake/polyyeld/background.png',
        text: `PolyYeld Finance is a next-generation yield farming protocol on the Polygon network with lots of unique and creative features that enable you to earn a passive income. We are trying to create a protocol like Yearn, but with a reduced token supply with high value for Polygon Network users, LP providers, and stakers. There will only be 62,100 YELD tokens which will be minted throughout the course of 2-3 months.`,
        website: 'https://polyyeld.finance/',
        social: {
          telegram: 'https://t.me/polyyeld',
          discord: 'https://discord.gg/8nK8aQqnCc',
          twitter: 'https://twitter.com/PolyYeldFinance',
        },
      },
    ],
  },
  {
    id: 'moo_aave-eth',
    name: 'Iron / Garuda / Fanatics',
    logo: 'single-assets/ETH.svg',
    token: 'mooAaveETH',
    tokenDecimals: 18,
    tokenAddress: '0x77276a7c9Ff3a6cbD334524d6F1f6219D039ac0E',
    tokenOracle: 'tokens',
    tokenOracleId: 'ETH',
    earnedToken: 'mooPolygonBIFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    earnContractAddress: '0x9B508ad657ed5A139D1a7c97fD84d7B7240849Cf',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BIFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1622830150,
    partners: [
      {
        logo: 'stake/garuda/logo.png',
        background: 'stake/polygon/background.png',
        text: `GarudaSwap is the 1st Next Generation Automatic Liquidity Acquisition yield farm and AMM decentralized exchange running on Binance Smart Chain with lots of unique and creative features that let you earn and win for a long time.`,
        website: 'https://garudaswap.finance/',
        social: {
          telegram: 'https://t.me/garudaswap',
          twitter: 'https://twitter.com/GarudaSwap',
        },
      },
      {
        logo: 'stake/ironfinance/logo.png',
        background: 'stake/polygon/background.png',
        text: `Inspired by FRAX, a unique fractionally-algorithmic stablecoin on the Ethereum network, and utilizing a similar approach, we have created IRON, the first partially-collateralized stablecoin on Binance Smart Chain. The IRON protocol makes use of 2 tokens to achieve its goal:  STEEL and IRON. 
      STEEL - The share token of the Iron finance protocol. Serves as part of the collateral behind IRON. Backed by seigniorage revenue as well as the value of any excess collateral. 
      IRON -  A stablecoin pegged to $1. Partially backed by a continuously adjusting ratio of collateral equal to $1 in value.
      Check out the docs for more information: https://docs.iron.finance/`,
        website: 'https://app.iron.finance/',
        social: {
          telegram: 'https://t.me/ironfinance',
          twitter: 'https://twitter.com/IronFinance',
        },
      },
      {
        logo: 'stake/fanatics/logo.png',
        background: 'stake/polygon/background.png',
        text: `fanatics.finance is a whole new Decentralized Exchange on Binance smart chain as known as 3° Generation yield farming mechanism that allows perpetual price increase with a sustainable and profitable farming yield with a Timelock contract at launch! And numerous new Features. Fanatics Finance is under Fanatics Finance is under license from KSOC Sports OÜ Registration: 16197453 Harju County, Tallinn, Kesklinna district, Pärnu mnt 158, ESTONIA.`,
        website: 'https://fanaticsfinance.com/',
        social: {
          telegram: 'https://t.me/fanaticsfinance_EN',
          twitter: 'https://twitter.com/fanaticsfinance',
        },
      },
    ],
  },
  {
    id: 'moo_aave-btc',
    name: 'Iron / Garuda / Fanatics',
    logo: 'single-assets/BTCB.svg',
    token: 'mooAaveWBTC',
    tokenDecimals: 8,
    tokenAddress: '0xD3395577febc6AdaB25490a69955ebC47040766C',
    tokenOracle: 'tokens',
    tokenOracleId: 'WBTC',
    earnedToken: 'mooPolygonBIFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xfEcf784F48125ccb7d8855cdda7C5ED6b5024Cb3',
    earnContractAddress: '0x20948Cad130c3D7B24d27CC66163b4aaed4684F0',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BIFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1622830106,
    partners: [
      {
        logo: 'stake/garuda/logo.png',
        background: 'stake/polygon/background.png',
        text: `GarudaSwap is the 1st Next Generation Automatic Liquidity Acquisition yield farm and AMM decentralized exchange running on Binance Smart Chain with lots of unique and creative features that let you earn and win for a long time.`,
        website: 'https://garudaswap.finance/',
        social: {
          telegram: 'https://t.me/garudaswap',
          twitter: 'https://twitter.com/GarudaSwap',
        },
      },
      {
        logo: 'stake/ironfinance/logo.png',
        background: 'stake/polygon/background.png',
        text: `Inspired by FRAX, a unique fractionally-algorithmic stablecoin on the Ethereum network, and utilizing a similar approach, we have created IRON, the first partially-collateralized stablecoin on Binance Smart Chain. The IRON protocol makes use of 2 tokens to achieve its goal:  STEEL and IRON. 
      STEEL - The share token of the Iron finance protocol. Serves as part of the collateral behind IRON. Backed by seigniorage revenue as well as the value of any excess collateral. 
      IRON -  A stablecoin pegged to $1. Partially backed by a continuously adjusting ratio of collateral equal to $1 in value.
      Check out the docs for more information: https://docs.iron.finance/`,
        website: 'https://app.iron.finance/',
        social: {
          telegram: 'https://t.me/ironfinance',
          twitter: 'https://twitter.com/IronFinance',
        },
      },
      {
        logo: 'stake/fanatics/logo.png',
        background: 'stake/polygon/background.png',
        text: `fanaticsfinance is a whole new Decentralized Exchange on Binance smart chain as known as 3° Generation yield farming mechanism that allows perpetual price increase with a sustainable and profitable farming yield with a Timelock contract at launch! And numerous new Features. Fanatics Finance is under Fanatics Finance is under license from KSOC Sports OÜ Registration: 16197453 Harju County, Tallinn, Kesklinna district, Pärnu mnt 158, ESTONIA.`,
        website: 'https://fanaticsfinance.com/',
        social: {
          telegram: 'https://t.me/fanaticsfinance_EN',
          twitter: 'https://twitter.com/fanaticsfinance',
        },
      },
    ],
  },
];
