export const bscPools = [
  {
    id: 'bomb-phub',
    logo: 'single-assets/PHUB.png',
    name: 'PHUB Maxi',
    token: 'PHUB',
    tokenDescription: 'PegHub.com',
    tokenAddress: '0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
    tokenDecimals: 18,
    earnedToken: 'bombfarmPHUB',
    earnedTokenAddress: '0x7d18710BC1d1c55f41652Aa00643289BF3CAB4E5',
    earnContractAddress: '0x7d18710BC1d1c55f41652Aa00643289BF3CAB4E5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'PHUB',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PegHub',
    assets: ['PHUB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?outputCurrency=0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
    createdAt: 1648477070,
  },
  {
    id: 'bomb-bitshare-bomb',
    name: 'BITSHARE-BOMB LP',
    token: 'BITSHARE-BOMB LP',
    tokenDescription: 'bitbomb.io',
    tokenAddress: '0x1B3B311F08Bc8F0976Ba9D6f8eb826DCBa5772eA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bombfarmBITSHARE-BOMB',
    earnedTokenAddress: '0x176eBc6a6C86225488b1b79c9978fDdD48c9088a',
    earnContractAddress: '0x176eBc6a6C86225488b1b79c9978fDdD48c9088a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-bitshare-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BOMB.money',
    assets: ['BITSHARE', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0x522348779DCb2911539e76A1042aA922F9C47Ee3/0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?outputCurrency=0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  },
  {
    id: 'bomb-czshares-bomb',
    name: 'CZSHARES-BOMB LP',
    token: 'CZSHARES-BOMB LP',
    tokenDescription: 'CZpegs.com',
    tokenAddress: '0xCF632b5D1c90C063645bAE91eeAf7Ba8a828C885',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'czfarmsCZSHARE-BOMB',
    earnedTokenAddress: '0x1946c2eb6594706B7900f574Fb1D23F998119DE4',
    earnContractAddress: '0x1946c2eb6594706B7900f574Fb1D23F998119DE4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-czshares-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CZpegs',
    assets: ['CZSHARES', 'BOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0x522348779DCb2911539e76A1042aA922F9C47Ee3/0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?outputCurrency=0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  },
  {
    id: 'bitbomb-bitbtc-btcb',
    name: 'BITBTC-BTCB LP',
    token: 'BITBTC-BTCB LP',
    tokenDescription: 'bitbomb.io',
    tokenAddress: '0x601E4fAbFC98933b60DcE8d198b936F2D2D5aA09',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bitfarmBITBTC-BTCB',
    earnedTokenAddress: '0x7fDA4FB84495d8068452983a35bFfd4454aa1D97',
    earnContractAddress: '0x7fDA4FB84495d8068452983a35bFfd4454aa1D97',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bitbomb-bitbtc-btcb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bitBOMB',
    assets: ['BITBTC', 'BTCB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x39739A969ff667C91a81252b3DDDFAe31aaE4Aeb',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&outputCurrency=0x39739A969ff667C91a81252b3DDDFAe31aaE4Aeb',
  },

  {
    id: 'bitbomb-bitdot-dot',
    name: 'BITDOT-DOT LP',
    token: 'BITDOT-DOT LP',
    tokenDescription: 'bitbomb.io',
    tokenAddress: '0xcd47cB7E1e0E9a4DEc4a1C02A3a6bACE79134622',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bitfarmBITDOT-DOT',
    earnedTokenAddress: '0x78343Cf1Ba99dfdb577C22beD83cc9F9A779d16C',
    earnContractAddress: '0x78343Cf1Ba99dfdb577C22beD83cc9F9A779d16C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bitbomb-bitdot-dot',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bitBOMB',
    assets: ['BITDOT', 'DOT'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402/0x123c62f04c9D70bD6F04E6282434c728F04eBD50',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402&outputCurrency=0x123c62f04c9D70bD6F04E6282434c728F04eBD50',
  },
  {
    id: 'bitbomb-bitatom-atom',
    name: 'BITATOM-ATOM LP',
    token: 'BITATOM-ATOM LP',
    tokenDescription: 'bitbomb.io',
    tokenAddress: '0xBc60EDc20e537a85F1EB91f471e32A8839B92722',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bitfarmBITATOM-ATOM',
    earnedTokenAddress: '0xd55de3458A7986e43f734d2bdc51d7D9269BA470',
    earnContractAddress: '0xd55de3458A7986e43f734d2bdc51d7D9269BA470',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bitbomb-bitatom-atom',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bitBOMB',
    assets: ['BITATOM', 'ATOM'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0x0Eb3a705fc54725037CC9e008bDede697f62F335/0x3CdC14690B4930f8b61A2195062f5AB8A6beC48d',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=0x0Eb3a705fc54725037CC9e008bDede697f62F335&outputCurrency=0x3CdC14690B4930f8b61A2195062f5AB8A6beC48d',
  },
  {
    id: 'bitbomb-bitada-ada',
    name: 'BITADA-ADA LP',
    token: 'BITADA-ADA LP',
    tokenDescription: 'bitbomb.io',
    tokenAddress: '0x3E784ce022D55bCfD87F6D7281fE5aAcD6e44018',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bitfarmBITADA-ADA',
    earnedTokenAddress: '0xa00260f157C2b2997C58DA2b1F89A61f8C5Ce796',
    earnContractAddress: '0xa00260f157C2b2997C58DA2b1F89A61f8C5Ce796',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bitbomb-bitada-ada',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bitBOMB',
    assets: ['BITADA', 'ADA'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/0x9F45C43c5Ed3c1a7FD7fb9f3b611860AE4dF2453',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47&outputCurrency=0x9F45C43c5Ed3c1a7FD7fb9f3b611860AE4dF2453',
  },
  {
    id: 'bomb-phub-btcb',
    name: 'PHUB-BTCB LP',
    token: 'PHUB-BTCB LP',
    tokenDescription: 'PegHub.com',
    tokenAddress: '0x0ccf8B264A333d73162c2Bb32dFE27187703ccfa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'czfarmPHUB-BTCB',
    earnedTokenAddress: '0xA0444032c66c063aBfe8bC9320E5A38c482Fe66e',
    earnContractAddress: '0xA0444032c66c063aBfe8bC9320E5A38c482Fe66e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-phub-btcb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CZpegs',
    assets: ['PHUB', 'BTCB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&outputCurrency=0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
  },
  {
    id: 'bomb-czbnb-bnb',
    name: 'CZBNB-BNB LP',
    token: 'CZBNB-BNB LP',
    tokenDescription: 'CZpegs.com',
    tokenAddress: '0x6773C6b86c15A9c10219eb92c036DE3F8F2ae956',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'czfarmCZBNB-BNB',
    earnedTokenAddress: '0x881598931619636D4A3377219A702a80F24D6505',
    earnContractAddress: '0x881598931619636D4A3377219A702a80F24D6505',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-czbnb-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CZpegs',
    assets: ['CZBNB', 'BNB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://www.bombswap.xyz/add/BNB/0xf43fffb83D7C73275c2feaAC15D5670f7D739B50',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=BNB&outputCurrency=0xf43fffb83D7C73275c2feaAC15D5670f7D739B50',
  },
  {
    id: 'bomb-czeth-eth',
    name: 'CZETH-ETH LP',
    token: 'CZETH-ETH LP',
    tokenDescription: 'CZpegs.com',
    tokenAddress: '0xC0fcB9bA8da90Bd3ee135ab6efBDE644f72270bD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'czfarmCZETH-ETH',
    earnedTokenAddress: '0xb3A5713235a5b864d51c3aB4d5b6f68950297967',
    earnContractAddress: '0xb3A5713235a5b864d51c3aB4d5b6f68950297967',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-czeth-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CZpegs',
    assets: ['CZETH', 'ETH'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0xbaBC232Ad871f36A6487C2dC117CbB4f2970EaA0',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0xbaBC232Ad871f36A6487C2dC117CbB4f2970EaA0',
  },
  {
    id: 'bomb-czbomb-bomb',
    name: 'CZBOMB-BOMB LP',
    token: 'CZBOMB-BOMB LP',
    tokenDescription: 'CZpegs.com',
    tokenAddress: '0x84b860ED296b12615b3bA50A7BE482E8eF2699B1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'czfarmCZBOMB-BOMB',
    earnedTokenAddress: '0x2a4a10237CBec8ef5FFe4eE4770D467966c4FEc4',
    earnContractAddress: '0x2a4a10237CBec8ef5FFe4eE4770D467966c4FEc4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-czbomb-bomb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CZpegs',
    assets: ['BOMB', 'CZBOMB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0x737BC70D07aA6B7f4Ba6d12B45E8CDDBa2446B77/0x522348779DCb2911539e76A1042aA922F9C47Ee3',

    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=BNB&outputCurrency=0x737BC70D07aA6B7f4Ba6d12B45E8CDDBa2446B77',
  },
  {
    id: 'bomb-czbusd-busd',
    name: 'CZBUSD-BUSD LP',
    token: 'CZBUSD-BUSD LP',
    tokenDescription: 'CZpegs.com',
    tokenAddress: '0x16254Cd930547e9591cdF4e3594f32857d106E12',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'czfarmCZBUSD-BUSD',
    earnedTokenAddress: '0x447D793c9ff744a88758eADa0E90DaCF3B8534bE',
    earnContractAddress: '0x447D793c9ff744a88758eADa0E90DaCF3B8534bE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-czbusd-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CZpegs',
    assets: ['CZBUSD', 'BUSD'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0xa700Ec63f9D3D9A03981CF7F8Eb805c444EDBC21/0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=BNB&outputCurrency=0xa700Ec63f9D3D9A03981CF7F8Eb805c444EDBC21',
  },
  // {
  //   id: 'bomb-bomb-btcb',
  //   name: 'BOMB-BTCB LP',
  //   token: 'BOMB-BTCB LP',
  //   tokenDescription: 'bomb.money',
  //   tokenAddress: '0x84392649eb0bC1c1532F2180E58Bae4E1dAbd8D6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'bombfarmBOMB-BTCB',
  //   earnedTokenAddress: '0x94E85B8E050F3F281CB9597cc0144F1F7AF1fe9B',
  //   earnContractAddress: '0x94E85B8E050F3F281CB9597cc0144F1F7AF1fe9B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bomb-bomb-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'active',
  //   platform: 'BOMB.money',
  //   assets: ['BOMB', 'BTCB'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  //   buyTokenUrl:
  //     'https://app.bogged.finance/bsc/swap?tokenIn=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&tokenOut=0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  // },
  // {
  //   id: 'bomb-phub-bomb',
  //   name: 'PHUB-BOMB LP',
  //   token: 'PHUB-BOMB LP',
  //   tokenDescription: 'PegHub',
  //   tokenAddress: '0x03d5a30Fde7dF6E34b79a3Bb51bAb2c4bEBe001E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'bombfarmPHUB-BOMB',
  //   earnedTokenAddress: '0x53f7EA210bE868C7453FABFF6c095744B4e4693C',
  //   earnContractAddress: '0x53f7EA210bE868C7453FABFF6c095744B4e4693C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bomb-phub-bomb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'active',
  //   platform: 'BOMB.money',
  //   assets: ['PHUB', 'BOMB'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://www.bombswap.xyz/add/0x522348779DCb2911539e76A1042aA922F9C47Ee3/0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
  //   buyTokenUrl:
  //     'https://www.bombswap.xyz/swap?outputCurrency=0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
  // },
  // {
  //   id: 'bomb-bomb-bshare',
  //   name: 'BOMB-BSHARE LP',
  //   token: 'BOMB-BSHARE LP',
  //   tokenDescription: 'bomb.money',
  //   tokenAddress: '0x54f9fE531224Fa43Feb218B20ABa84d22a8fDc0C',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'bomfarmBOMB-BSHARE',
  //   earnedTokenAddress: '0x4d574e560Fc5C2dc8997c13e1a5eEd1941dF9914',
  //   earnContractAddress: '0x4d574e560Fc5C2dc8997c13e1a5eEd1941dF9914',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bomb-bomb-bshare',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'active',
  //   platform: 'BOMB.money',
  //   assets: ['BSHARE', 'BOMB'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://www.bombswap.xyz/add/0x522348779DCb2911539e76A1042aA922F9C47Ee3/0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
  //   buyTokenUrl:
  //     'https://www.bombswap.xyz/swap?outputCurrency=0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  // },
  // {
  //   id: 'bomb-busm-busd',
  //   name: 'BUSM-BUSD LP',
  //   token: 'BUSM-BUSD LP',
  //   tokenDescription: 'busm.money',
  //   tokenAddress: '0xEe46Bd06a8876c3cc86027dc7D2Df19af513cD12',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'bomfarmBUSM-BUSD',
  //   earnedTokenAddress: '0x34FCaeCd4a85cE2089b72cb3B63d1c82Cd86Ee81',
  //   earnContractAddress: '0x34FCaeCd4a85cE2089b72cb3B63d1c82Cd86Ee81',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bomb-busm-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'active',
  //   platform: 'BOMB.money',
  //   assets: ['BUSM', 'BUSD'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x6216B17f696B14701E17BCB24Ec14430261Be94A/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://app.bogged.finance/bsc/swap?tokenIn=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&tokenOut=0x6216B17f696B14701E17BCB24Ec14430261Be94A',
  // },
  {
    id: 'bitbomb-bitshare-btcb',
    name: 'BITSHARE-BTCB LP',
    token: 'BITSHARE-BTCB LP',
    tokenDescription: 'bitbomb.io',
    tokenAddress: '0x9b9f8a291eabd58Bec64315D9bB1c4E99789177B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'bitfarmBITSHARE-BTCB',
    earnedTokenAddress: '0x6eF65abE7727d9bF021dB3B37A736353dB810166',
    earnContractAddress: '0x6eF65abE7727d9bF021dB3B37A736353dB810166',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bitbomb-bitshare-btcb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'bitBOMB',
    assets: ['BITSHARE', 'BTCB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x116B19FBAFCc947E7Ffcc3A42ff7185589143a56',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&outputCurrency=0x116B19FBAFCc947E7Ffcc3A42ff7185589143a56',
  },
  {
    id: 'bomb-czshares-bnb',
    name: 'CZSHARES-BNB LP',
    token: 'CZSHARES-BNB LP',
    tokenDescription: 'CZpegs.com',
    tokenAddress: '0x08E7b8D65045F9cC497b2Bd5BcB59b2eF9c76dA1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'czfarmCZSHARES-BNB',
    earnedTokenAddress: '0x3AF94aD0323be1b2b946fCDa05221D078B2AF708',
    earnContractAddress: '0x3AF94aD0323be1b2b946fCDa05221D078B2AF708',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-czshares-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'CZpegs',
    assets: ['CZSHARES', 'BNB'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://www.bombswap.xyz/add/BNB/0x8169a36EC368882207515D6F509A2E7Fc74203d3',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=BNB&outputCurrency=0x8169a36EC368882207515D6F509A2E7Fc74203d3',
  },
  // {
  //   id: 'bomb-bshare-wbnb',
  //   name: 'BSHARE-BNB LP',
  //   token: 'BSHARE-BNB LP',
  //   tokenDescription: 'bomb.money',
  //   tokenAddress: '0x1303246855b5B5EbC71F049Fdb607494e97218f8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'bomfarmBSHARE-BNB',
  //   earnedTokenAddress: '0x60c08c4442E200d609D05df6bE327e3bA836971C',
  //   earnContractAddress: '0x60c08c4442E200d609D05df6bE327e3bA836971C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bomb-bshare-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'active',
  //   platform: 'BOMB.money',
  //   assets: ['BSHARE', 'BNB'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
  //   buyTokenUrl:
  //     'https://app.bogged.finance/bsc/swap?tokenIn=BNB&tokenOut=0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
  // },
  // {
  //   id: 'bomb-bomb-maxi',
  //   name: 'BOMB-MAXI 80-20',
  //   token: 'BOMB-MAXI LP',
  //   tokenDescription: 'bomb.money (ACSI)',
  //   tokenAddress: '0xd6F52e8AB206e59A1E13b3D6c5B7f31E90ef46EF',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'bombfarm80BOMB',
  //   earnedTokenAddress: '0x9A9698F46769f178b4F0a68653bDD22186147D4f',
  //   earnContractAddress: '0x9A9698F46769f178b4F0a68653bDD22186147D4f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bomb-bomb-maxi',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'active',
  //   platform: 'BOMB.money',
  //   assets: ['BOMB', 'BTCB'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratMultiLP',
  //   addLiquidityUrl:
  //     'https://app.acsi.finance/#/pool/0xd6f52e8ab206e59a1e13b3d6c5b7f31e90ef46ef000200000000000000000028/invest',
  //   buyTokenUrl:
  //     'https://app.bogged.finance/bsc/swap?tokenIn=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&tokenOut=0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  // },
  // {
  //   id: 'bomb-bshare-maxi',
  //   name: 'BSHARE-MAXI 80-20',
  //   token: 'BSHARE-MAXI LP',
  //   tokenDescription: 'bomb.money (ACSI)',
  //   tokenAddress: '0x2C374eD1575e5C2C02c569f627299E902A1972cb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'bombfarm80BSHARE',
  //   earnedTokenAddress: '0x25FF492E85d00E4C9883aa1710B52987B91d42F3',
  //   earnContractAddress: '0x25FF492E85d00E4C9883aa1710B52987B91d42F3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bomb-bshare-maxi',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'active',
  //   platform: 'BOMB.money',
  //   assets: ['BSHARE', 'BNB'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratMultiLP',
  //   addLiquidityUrl:
  //     'https://app.acsi.finance/#/pool/0x2c374ed1575e5c2c02c569f627299e902a1972cb000200000000000000000027/invest',
  //   buyTokenUrl:
  //     'https://app.bogged.finance/bsc/swap?tokenIn=BNB&tokenOut=0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
  // },
  // {
  //   id: 'bomb-bomb',
  //   name: 'BOMB',
  //   token: 'BOMB',
  //   tokenDescription: 'bomb.money',
  //   tokenAddress: '0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'bomfarmBOMB',
  //   earnedTokenAddress: '0x932DFde877f3FcB5C5b809BB7e3bcF7EE4310BCa',
  //   earnContractAddress: '0x932DFde877f3FcB5C5b809BB7e3bcF7EE4310BCa',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BOMB',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'active',
  //   platform: 'BOMB.money',
  //   assets: ['BOMB', 'BSHARE'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'SingleStake',
  //   buyTokenUrl:
  //     'https://app.bogged.finance/bsc/swap?tokenIn=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&tokenOut=0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  // },
  {
    id: 'bomb-czemp-emp',
    name: 'CZEMP-EMP LP',
    token: 'CZEMP-EMP LP',
    tokenDescription: 'CZpegs.com',
    tokenAddress: '0xdDb1046a98A1068d1dCC3229699e3a1ee203b6D8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'czfarmCZEMP-EMP',
    earnedTokenAddress: '0xc73A59A40AE80E75e4D308C2A96dc14558Da4810',
    earnContractAddress: '0xc73A59A40AE80E75e4D308C2A96dc14558Da4810',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bomb-czemp-emp',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'CZpegs',
    assets: ['CZEMP', 'EMP'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bombswap.xyz/add/0xa700Ec63f9D3D9A03981CF7F8Eb805c444EDBC21/0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
    buyTokenUrl:
      'https://www.bombswap.xyz/swap?inputCurrency=BNB&outputCurrency=0xa700Ec63f9D3D9A03981CF7F8Eb805c444EDBC21',
  },
];
