export const polygonPools = [
  {
    id: 'polypegs-pmatic-matic',
    name: 'PMATIC-MATIC LP',
    token: 'PMATIC-MATIC LP',
    tokenDescription: 'polypegs.com',
    tokenAddress: '0x5328221B7BF04846291a7Ca2803c7786f9806Ea1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'polypegsPMATIC-MATIC',
    earnedTokenAddress: '0xd7a587c444f5B6cd199Ef4bdE60459046992f33f',
    earnContractAddress: '0xd7a587c444f5B6cd199Ef4bdE60459046992f33f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypegs-pmatic-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'polypegs',
    assets: ['PMATIC', 'MATIC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://swap.peghub.com/add/ETH/0xB7e1E341b2CBCc7d1EdF4DC6E5e962aE5C621ca5',
    buyTokenUrl:
      'https://swap.peghub.com/swap?inputCurrency=ETH&outputCurrency=0xB7e1E341b2CBCc7d1EdF4DC6E5e962aE5C621ca5',
  },
  {
    id: 'polypegs-pshare-matic',
    name: 'PSHARE-MATIC LP',
    token: 'PSHARE-MATIC LP',
    tokenDescription: 'polypegs.com',
    tokenAddress: '0x3E1d352F66b4d3F56043C66d5e59367624B3757E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'polypegsPSHARE-MATIC',
    earnedTokenAddress: '0x402ac211A4E1e34078af06BA0752e5837D095e10',
    earnContractAddress: '0x402ac211A4E1e34078af06BA0752e5837D095e10',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'polypegs-pshare-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'polypegs',
    assets: ['PSHARE', 'MATIC'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://swap.peghub.com/add/ETH/0x04b79c851ed1A36549C6151189c79EC0eaBca745',
    buyTokenUrl:
      'https://swap.peghub.com/swap?inputCurrency=ETH&outputCurrency=0x04b79c851ed1A36549C6151189c79EC0eaBca745',
  },
];
